import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import fluffyAlpacaLogo from '../assets/fluffyAlpacaLogo.png'
import AllMusicLinks from './allMusicLinks.js';

function NavBar() {
  const [isOpen, setIsOpen] = React.useState(false);

  const renderMobileNav = () => {
    if (isOpen) {
      return (
        <div className='mobile-items'>
          <AnchorLink href='#music' className='mobile-menu-links'>Music</AnchorLink>
          <AnchorLink href='#about' className='mobile-menu-links'>About</AnchorLink>
          <AnchorLink href='#merch' className='mobile-menu-links'>Merch</AnchorLink>
        </div>
      )
    }
  }

  return (
    <div className='nav-bar main-width'>
      <img src={fluffyAlpacaLogo} alt='logo text'/>

      <div className='mobile-menu-container'>
        <div className='hamburger-menu' onClick={() => setIsOpen(!isOpen)}>
          <div className='line'></div>
          <div className='line'></div>
          <div className='line'></div>
        </div>
        { renderMobileNav() }
      </div>

      <div className='desktop-items'>
        <AnchorLink href='#music'>Music</AnchorLink>
        <AnchorLink href='#about'>About</AnchorLink>
        <AnchorLink href='#merch'>Merch</AnchorLink>
        <AllMusicLinks />
      </div>
    </div>
  );
}

export default NavBar;