import React from 'react';


function MerchCard (props) {
  const {image, title, price, available} = props

  const renderButtonClasses = () => {
    return `merch-card-button ${available ? 'buy' : 'sold'}`
  }

  return (
    <div className='merch-card'>
      <img src={image} alt=''/>
      <div className='merch-card-content'>
        <h3 className='merch-card-title'>{title}</h3>
        <p className='merch-card-price'>{price}</p>
      </div>
      <button className={renderButtonClasses()}>
        {available ? 'BUY NOW' : 'SOLD OUT'}
      </button>
    </div>
  );
}

export default MerchCard;