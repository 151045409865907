import React from 'react';
import heroAlpaca from '../assets/heroAlpaca.png'
import NavBar from '../components/navBar.js'

function LandingPage() {
  return (
    <div className='landing-page-background section'>
      <NavBar />
      <div>
        <img className='hero-image' src={heroAlpaca} alt='hero alpaca'/>
      </div>
      <div className='fake-nav-bar'></div>
    </div>
  )
}

export default LandingPage;