import React from 'react';

function MusicLink (props) {
  const {image, link, altText} = props

  if (link) {
    return (
      <a href={link} className='music-link' target="_blank" rel="noopener noreferrer">
        <img src={image} alt={altText} />
      </a>
    );
  }
  return null
}

export default MusicLink;