import React from 'react';
import spotifyIcon from '../assets/spotifyIcon.png'
import appleMusicIcon from '../assets/appleMusicIcon.png'
import soundcloudIcon from '../assets/soundcloudIcon.png'
import instagramIcon from '../assets/instagramIcon.png'
import facebookIcon from '../assets/facebookIcon.png'
import MusicLink from '../components/musicLink.js'

const linkData = [
  {
    image: spotifyIcon,
    link: 'https://open.spotify.com/artist/3wSVL5YLrRYnSEi9RVlvbI?si=ZuzQ1ttAQEqap8WUHgcJYQ',
    altText: 'Spotify'
  },
  {
    image: appleMusicIcon,
    link: 'https://music.apple.com/us/artist/fluffy-alpaca/1689455181',
    altText: 'Apple Music'
  },
  {
    image: soundcloudIcon,
    link: 'https://soundcloud.com/fluffyalpacamusic',
    altText: 'Soundcloud'
  },
  {
    image: instagramIcon,
    link: 'https://instagram.com/fluffyalpacamusic',
    altText: 'Instagram'
  },
  {
    image: facebookIcon,
    link: 'https://facebook.com/fluffyalpacamusic',
    altText: 'Facebook'
  },
]

function AllMusicLinks () {
  const renderMusicLinks = () => {
    return linkData.map((item, index) => {
      const {image, link, altText} = item
      return <MusicLink key={index} image={image} link={link} altText={altText} />
    })
  }

  return (
    <span>
      {renderMusicLinks()}
    </span>
  );
}

export default AllMusicLinks;