import './App.css';
import LandingPage from './pages/landingPage.js';
import MusicPage from './pages/musicPage.js';
import AboutPage from './pages/aboutPage.js';
import MerchPage from './pages/merchPage.js';
import Footer from './components/footer.js'

function App() {
  return (
    <div className="App"  id="outer-container">
      <LandingPage />
      <MusicPage />
      <AboutPage />
      <MerchPage />
      <Footer />
    </div>
  );
}

export default App;
