import React from 'react';
import AllMusicLinks from './allMusicLinks.js';
import simpleLogo from '../assets/simpleLogo.png'

function Footer() {
  return (
    <div className='footer main-width'>
      <div className='footer-left'>
        <h3>Contact Management</h3>
        <h3>fluffyalpaca37@gmail.com</h3>
      </div>
      <div className='footer-middle'>
        <img src={simpleLogo} alt='main logo'/>
      </div>
      <div className='footer-right'>
        <AllMusicLinks />
      </div>
    </div>
  );
}

export default Footer;