import React from 'react';
import MusicLink from './musicLink.js';
import spotifyIcon from '../assets/spotifyIcon.png'
import appleMusicIcon from '../assets/appleMusicIcon.png'
import soundcloudIcon from '../assets/soundcloudIcon.png'

function MusicCard (props) {
  const {albumImage, name, spotifyLink, appleLink, soundCloudLink, available, mobileImage} = props

  return (
    <div className='music-card'>
      {/* { available ? null : <div className='music-card-hide'></div>} */}
      <img className='music-card-album-cover desktop' src={albumImage} alt='album cover' />
      <img className='music-card-album-cover mobile' src={mobileImage} alt='album cover' />
      <h2>{name}</h2>
      <p>FLUFFY ALPACA</p>
      <div>
        <MusicLink image={spotifyIcon} link={spotifyLink} altText='spotify'/>
        <MusicLink image={appleMusicIcon} link={appleLink} altText='apple'/>
        <MusicLink image={soundcloudIcon} link={soundCloudLink} altText='soundcloud'/>
      </div>
    </div>
  );
}

export default MusicCard;