import React from 'react';
import MusicCard from '../components/musicCard.js';
import secretsAlbum from '../assets/secretsAlbum.jpeg'
import secretsAlbum_mobile from '../assets/secretsAlbum_mobile.jpeg'
import tonightAlbum from '../assets/tonightAlbum.jpeg'
import tonightAlbum_mobile from '../assets/tonightAlbum_mobile.jpeg'
import withoutYouAlbum from '../assets/withoutYouAlbum.png'
import withoutYouAlbum_mobile from '../assets/withoutYouAlbum_mobile.png'
import heavenAlbum from '../assets/heavenAlbum.jpg'
import heavenAlbum_mobile from '../assets/heavenAlbum_mobile.jpg'

function MusicPage () {
  const albumData = [
    {
      image: secretsAlbum,
      mobileImage: secretsAlbum_mobile,
      name: 'Secrets',
      spotifyLink: 'https://open.spotify.com/track/6gDtvrvzttVSSYnRsqisAm?si=99f2959e78594ca1',
      appleLink: 'https://music.apple.com/us/album/secrets/1715555341?i=1715555342',
      soundCloudLink: 'https://on.soundcloud.com/CG8h8',
      available: true
    },
    {
      image: tonightAlbum,
      mobileImage: tonightAlbum_mobile,
      name: 'Tonight',
      spotifyLink: 'https://open.spotify.com/track/7MjS9t8O6zqVVx1raiWu6S?si=63423e9507614d0d',
      appleLink: 'https://music.apple.com/us/album/tonight/1689455521?i=1689455522',
      soundCloudLink: 'https://on.soundcloud.com/tpQrn',
      available: true
    },
    {
      image: withoutYouAlbum,
      mobileImage: withoutYouAlbum_mobile,
      name: 'Without You',
      soundCloudLink: 'https://on.soundcloud.com/dVPuq',
      available: true
    },
    {
      image: heavenAlbum,
      mobileImage: heavenAlbum_mobile,
      name: 'Heaven',
      soundCloudLink: 'https://on.soundcloud.com/FnzBi',
      available: true
    },
  ]

  const renderCards = () => {
    return albumData.map((item, index) => {
      return (
        <MusicCard
          key={index}
          albumImage={item.image}
          name={item.name}
          spotifyLink={item.spotifyLink}
          appleLink={item.appleLink}
          soundCloudLink={item.soundCloudLink}
          available={item.available}
          mobileImage={item.mobileImage}
        />
      )
    })
  }

  return (
    <div id='music' className='section music-page main-width'>
      { renderCards() }
    </div>
  );
}

export default MusicPage;