import React from 'react';
import aboutArtist from '../assets/artistPic2.jpeg'

function AboutPage () {
  return (
    <div id='about' className='about-page-background section main-width'>
      <img src={aboutArtist} alt='about artist'/>
      <div className='about-artist-content'>
        <h2 className='section-title'>About the Artist</h2>
        <p>
          Meet DJ Fluffy Alpaca, the sonic visionary from Albuquerque captivating hearts with cosmic house vibes. Seamlessly blending genres, his sets journey from soulful melodies to hypnotic tech-house, uniting dancefloors in euphoria. Inspired by New Mexico's beauty, their music paints landscapes of sound, inviting both veterans and newcomers to lose themselves.
        </p>
        <p>
          Adorned in vibrant attire that mirrors their Southwestern roots, DJ Fluffy Alpaca's stage presence is as captivating as his mixes. With a devoted following and a passion for unifying beats, he transforms every venue into a celestial sanctuary of rhythm and connection.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;