import React from 'react';
import MerchCard from '../components/merchCard.js'
import shirtMerch from '../assets/shirtMerch.jpg'
import hatMerch from '../assets/hatMerch.jpg'
import jacketMerch from '../assets/jacketMerch.jpg'

const merchData = [
  {
    image: jacketMerch,
    title: 'Bomber Jacket',
    price: '$90',
    available: false
  },
  // {
  //   image: hatMerch,
  //   title: 'Hat',
  //   price: '$25',
  //   available: true
  // },
  // {
  //   image: shirtMerch,
  //   title: 'T-Shirt',
  //   price: '$35',
  //   available: true
  // }
]

function MerchPage () {
  const renderMerchCards = () => {
    return merchData.map((item, index) => {
      const {image, title, price, available} = item
      return <MerchCard key={index} image={image} title={title} price={price} available={available}/>
    })
  }

  return (
    <div id='merch' className='merch-page section main-width'>
      <h2 className='section-title'>Merch</h2>
      <div className='merch-cards-container'>
        { renderMerchCards() }
      </div>
    </div>
  );
}

export default MerchPage;